import {
  ClusterDataType,
  MetaData,
  NetworkingDataType,
  RepoModel,
  RepoOverviewModel,
  TreeView
} from "@types";

import { Api, api } from "./config";

class Respository {
  instance: Api;

  constructor(params: Api) {
    this.instance = params;
  }

  public getRepositoryRequest = (repoId: string) => {
    return this.instance.get<RepoModel>(`/repositories/${repoId}`);
  };

  public postRepositoryRequest = (payload: { url: string; token: string }) => {
    return this.instance.post<RepoModel>("/repositories", payload);
  };

  public getEmbedStatusRepositories = (repoId: string) => {
    return this.instance.post<{ status: string }>(`/repositories/${repoId}/embedd`);
  };

  public getProcessStatusRepositories = (repoId: string) => {
    return this.instance.post(`/repositories/${repoId}/blobs`);
  };

  public getDetailFileRepositories = (repoId: string, fileId: string) => {
    return this.instance.get(`/repositories/${repoId}/blob/${fileId}`);
  };

  public getChatRepositories = (
    repoId: string,
    payload: { prompt: string; history: string[] }
  ): Promise<any> => {
    return this.instance.post(`/repositories/${repoId}/chat`, payload);
  };

  public getGraphRepositories = (repoId: string): Promise<any> => {
    return this.instance.get(`/repositories/graphs/${repoId}`);
  };

  public uploadRepositoryRequest = (name: string, form: FormData) => {
    return this.instance.post<RepoModel>(`/repositories/upload?name=${name}`, form, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
  };

  public getClusterChartDataRequest = (repoId: string) => {
    return this.instance.get<ClusterDataType>(`/api/repository/${repoId}/clustering`);
  };

  public getGraphChartDataRequest = (repoId: string) => {
    return this.instance.get<NetworkingDataType>(`/api/repository/${repoId}/copy_graph`);
  };

  public getTreeViewDataRequest = (repoId: string) => {
    return this.instance.get<TreeView>(`/api/repository/${repoId}/tree_view`);
  };

  public postRepositoryMatchingRequest = (payload: {
    repoId: string;
    url: string;
    token?: string;
  }) => {
    return this.instance.post<RepoModel>(`api/repository/${payload.repoId}/matching/`, payload);
  };

  public getMatchingScoreDataRequest = (treeId: string) => {
    return this.instance.get<MetaData>(`/api/tree/${treeId}/matching_score`);
  };

  public getComplexityDataRequest = (repoId: string) => {
    return this.instance.get(`/api/repository/${repoId}/complexity`);
  };

  public getListRepositories = () => {
    return this.instance.get<Array<RepoOverviewModel>>("/repositories");
  };

  public postTriggerRepository = (repoId: string) => {
    return this.instance.post(`/api/repository/${repoId}/export_file`);
  };

  public getFileExcelRepository = (repoId: string, isCheckStatus?: boolean) => {
    return this.instance.get(
      `/api/repository/${repoId}/export_file`,
      !isCheckStatus ? { responseType: "blob" } : {}
    ) as unknown as Promise<Blob>;
  };

  public getReportDeadcodeRepository = (repoId: string) => {
    return this.instance.get(`/api/repository/${repoId}/deadcode`, {
      responseType: "blob"
    }) as unknown as Promise<Blob>;
  };

  public getAssessmentRepository = (repoId: string) => {
    return this.instance.get(`/api/repository/${repoId}/assessment`, {
      responseType: "blob"
    }) as unknown as Promise<Blob>;
  };
}

export const repositoryApi = new Respository(api);
