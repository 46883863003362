import { Suspense, lazy } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import { GlobalLoading } from "@components";
import { ROUTERS } from "@defines";

const LoginPage = lazy(() => import("@pages/login"));
const RegisterPage = lazy(() => import("@pages/register"));
const ForgotPasswordPage = lazy(() => import("@pages/forgotPassword"));
const VerifyPage = lazy(() => import("@pages/verify"));
const ResetPasswordPage = lazy(() => import("@pages/resetPassword"));
const NotFoundPage = lazy(() => import("@pages/notfound"));
const ServerErrorPage = lazy(() => import("@pages/serverError"));
const UnderMaintenancePage = lazy(() => import("@pages/underMaintenance"));
const PrivacyPolicyPage = lazy(() => import("@pages/privacyPolicy"));
const ThemePage = lazy(() => import("@pages/theme"));
const WorkspacePage = lazy(() => import("@pages/workspace"));

const InventoryPage = lazy(() => import("@pages/exploration/inventory"));
const ComplexityPage = lazy(() => import("@pages/exploration/complexity"));
const DataAssetPage = lazy(() => import("@pages/exploration/data-asset"));
const EntryPointsPage = lazy(() => import("@pages/exploration/entrypoints"));
const DependencyGraphPage = lazy(() => import("@pages/exploration/dependencygraph"));

const Repositories = lazy(() => import("@pages/workspace/content/Repositories"));

import { UserProvider } from "../providers/UserProvider";

import { ExplorationRouter } from "./ExplorationRouter";

export const Router: React.FC = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<GlobalLoading />}>
        <Routes>
          <Route path='/signin' element={<LoginPage />} />
          <Route path='/register' element={<RegisterPage />} />
          <Route path='/verify' element={<VerifyPage />} />
          <Route path='/forgot-password' element={<ForgotPasswordPage />} />
          <Route path='/resetpwd' element={<ResetPasswordPage />} />
          <Route path='/404' element={<NotFoundPage />} />
          <Route path='/500' element={<ServerErrorPage />} />
          <Route path='/under-maintenance' element={<UnderMaintenancePage />} />
          <Route path='/privacy-and-policy' element={<PrivacyPolicyPage />} />
          <Route path='/theme' element={<ThemePage />} />
          <Route element={<UserProvider />}>
            <Route element={<WorkspacePage />}>
              <Route path='/projects' element={<Repositories />} />
            </Route>
            <Route path='/exploration/:projectId' element={<ExplorationRouter />}>
              <Route path='assets'>
                <Route path='inventory' element={<InventoryPage />} />
                <Route path='complexity' element={<ComplexityPage />} />
                <Route path='data' element={<DataAssetPage />} />
                <Route path='*' element={<Navigate to='inventory' replace />} />
              </Route>
              <Route path='reverse'>
                <Route path='graph' element={<DependencyGraphPage />} />
                <Route path='entrypoints' element={<EntryPointsPage />} />
                <Route path='*' element={<Navigate to='graph' replace />} />
              </Route>
            </Route>
          </Route>
          {/* <Route path='/' element={(<EmptyRouter />)} /> */}
          <Route path='/' element={<Navigate to={"/projects"} />} />
          <Route path='*' element={<Navigate to={ROUTERS.NOT_FOUND} />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};
