import { createContext, useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import { ROUTERS } from "@defines";
import { useController } from "@hooks";

import { authApi } from "../api/auth";

const UserContext = createContext({});

export const UserProvider = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { controller } = useController();

  useEffect(() => {
    const getUser = async () => {
      controller(
        async () => {
          await authApi.verifyToken();
        },
        {
          onError() {
            const search = new URLSearchParams(`redirect=${location.pathname}`);
            const queries = search.toString();

            navigate(`${ROUTERS.LOGIN}${queries ? `?${queries}` : ""}`);
          }
        }
      );
    };

    getUser();
  }, []);
  return (
    <UserContext.Provider value={{}}>
      <Outlet />
    </UserContext.Provider>
  );
};
